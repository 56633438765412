import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import "./Organizational.css";

const Organizational = () => {
  const [tKey, i18n] = useTranslation();
  return (
    <section className="organizational" id="organizational">
      <Container dir="">
        <Row className="justify-content-center">
          <Col xl={4} className="col-auto content-organizational">
            <div>
              <p className="text-join">{tKey("JOIN OUR HEXA-STAGES")}</p>
              <p className="fw-bold text-organizational ">
                {tKey(
                  "ORGANIZATIONAL DEVELOPMENT & TRANSFORMATION (ODT) JOURNEY"
                )}
              </p>
                <p className="description">
                {tKey(
                  "SO WE CAN RETHINK YOUR REQUIREMENTS, ASPIRATIONS AND WAYS OF DOING, AND "
                )}
                <span className={styles.textColor}>
                  {tKey("TAKE YOUR BUSINESS TO NEW FUTURISTIC LEVELS")}
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Organizational;
